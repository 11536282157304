import stringify from "fast-json-stable-stringify";
import { action, makeObservable, observable } from "mobx";

import type { ICache } from "@ihr-radioedit/inferno-core";
import { ILog, Microsite, RequestProps, ResolvedBlockInterface, sdkOpts, Site } from "@ihr-radioedit/inferno-core";
import type { PageFragment } from "@ihr-radioedit/inferno-webapi";
import { IPage } from "../abstracts/store/base-page.abstract";
import { BaseStore, CcpaStatus, IStore } from "../abstracts/store/base-store.abstract";
import { isIHRSession, isPWSSession } from "../auth/backend";
import { PWSSessionState } from "../auth/backends/pws";
import { AdPlacement } from "../lib/ads";
import { IHRSessionState, SessionBackend } from "../lib/backend";
import { DefaultCategoriesCatalogResponse } from "../lib/default-category-type";
import { Tags } from "../lib/tagging-type";
import { GoogleMapsLibLoader } from "../loaders/google-maps-lib-loader";
import type { ABTestConfig } from "../services/variant-test";
import { isWindowDefined } from "../utilities/window";
import { StoreBlock } from "./block";
import { StoreDevice } from "./device";
import { StorePage } from "./page";
import { StorePlayer } from "./player";
import { StoreSession } from "./session";

const log = ILog.logger("Store");

/** Add new members to Base Abstract */
export class Store extends BaseStore {
  site: Site;

  @observable
  tags!: Tags;

  @observable
  adPlacements!: Map<string, AdPlacement>;

  @observable
  testGroups?: ABTestConfig;
  @observable
  request!: RequestProps;
  cache!: Map<string, ICache>;

  @observable
  page!: StorePage;
  @observable
  device!: StoreDevice;
  @observable
  player!: StorePlayer;

  constructor(initialData: IStore) {
    super();
    makeObservable(this);
    this.site = new Site(initialData.site.site, initialData.site.micrositeReferences);
    if (initialData.microsite) {
      this.microsite = new Microsite(initialData.microsite.microsite);
    }
    this.env = initialData.env;
    this.request = initialData.request;
    this.cache = observable.map(initialData.cache);
    this.adPlacements = observable.map(initialData.adPlacements);
    this.api_base_uri = initialData.api_base_uri;
    this.testGroups = initialData.testGroups ?? undefined;
    this.device = new StoreDevice(initialData.request.userAgent);
    this.player = new StorePlayer();
    this.block = new StoreBlock(initialData.block?.currentBlock);
    this.session = new StoreSession(initialData.session?.currentSession ?? null);
    this.page = new StorePage(initialData.page || ({} as IPage));
    this.disableThirdParty = !!initialData.disableThirdParty;
    this.defaultCategoryCatalog = initialData.defaultCategoryCatalog ?? undefined;

    this.tags = initialData.tags
      ? {
          ...initialData.tags,
          metadata: new Map(initialData.tags.metadata),
          surrogateKeys: new Set<string>(initialData.tags.surrogateKeys),
        }
      : {
          ttls: null,
          surrogateKeys: new Set<string>(),
          ads: null,
          analytics: null,
          metadata: new Map(),
        };
  }

  @action
  storeTags = (tags: Tags) => {
    this.tags = tags;
  };

  @action
  storeRedirect = (url: string, status: 301 | 302 = 302) => {
    log.debug(`${status} redirect to ${url}`);
    this.redirect = { url, status };
  };

  @action
  storeBlock = (block: ResolvedBlockInterface) => {
    // Only store block if it's primary and hasn't been stored yet
    if (
      this.page.currentPage &&
      this.primaryBlockId === block.id &&
      (!this.block.currentBlock || stringify(block) !== stringify(this.block.currentBlock))
    ) {
      this.block.storeBlock(block);
    }
  };

  @action
  storePrimaryBlockId = (primaryBlockId: string) => {
    this.primaryBlockId = primaryBlockId;
  };

  @action
  storePage = (page: PageFragment | null) => {
    this.page.storePage(page);
  };

  @action
  storeSession = (session: SessionBackend) => {
    this.session.storeSession(session);
  };

  @action
  storeMicrosite = (microsite: Microsite) => {
    this.microsite = microsite;
  };

  @action
  clearMicrosite = () => {
    this.microsite = undefined;
  };

  @action
  getGoogleMaps() {
    if (!this.googleMaps) {
      this.googleMaps = new GoogleMapsLibLoader(this.env.GOOGLE_MAPS_API_KEY);
    }
    return this.googleMaps;
  }

  @action
  storeRouteParams = (params: Record<string, unknown>) => {
    this.routeParams = params;
  };

  get isIHRSession() {
    if (this.session.currentSession?.authenticated && isIHRSession(this.session.currentSession.state)) {
      const compliance = (this.session.currentSession.state as IHRSessionState)?.profile?.privacyCompliance;
      return compliance?.privacyCompliance?.includes("CCPA") ? CcpaStatus.OptOut : CcpaStatus.OptIn;
    }
  }

  get isPWSSession() {
    if (this.session.currentSession?.authenticated && isPWSSession(this.session.currentSession.state)) {
      const compliance = !!(this.session.currentSession.state as unknown as PWSSessionState)?.profile?.privacy_optout;
      return compliance ? CcpaStatus.OptOut : CcpaStatus.OptIn;
    }
  }

  // @ts-expect-error - window.globalPrivacyControl is experimental
  // Note: OneTrust (C0004) value also accounts for GPC- so we don't have to check this ourselves; still keeping method for future reference
  gpcStatusOptOut = () => window.navigator?.globalPrivacyControl;

  isOptedOut = (ampStatus: CcpaStatus) => {
    if (ampStatus === CcpaStatus.OptOut || this.oneTrust?.isOptOut()) {
      return CcpaStatus.OptOut;
    }
  };

  profilePrivacyComplianceStatus = () => {
    const status = CcpaStatus.Anonymous;

    const ihrStatus = this.isIHRSession;
    if (ihrStatus) {
      return ihrStatus;
    }

    const pwsStatus = this.isPWSSession;
    if (pwsStatus) {
      return pwsStatus;
    }

    return status;
  };

  getCcpaStatus = () => {
    // we always opt-in on the server; but will be recalculated on client (article video monetization)
    if (!isWindowDefined()) {
      return CcpaStatus.OptIn;
    }

    const ampStatus = this.profilePrivacyComplianceStatus();

    return this.isOptedOut(ampStatus) ?? ampStatus;
  };

  @action
  storeCacheValue = (key: string, data: ICache) => {
    if (key && data) {
      this.cache.set(key, data);
    }
    return this.cache;
  };

  @action
  getCacheValue = (key: string) => {
    return this.cache.get(key);
  };

  hasCacheValue = (key: string) => {
    return this.cache && this.cache.has(key);
  };

  @action
  storeStackedNavState = (stacked: boolean) => {
    this.stackedNavState = stacked;
    return this.stackedNavState;
  };

  @action
  storeAdPlacement = (key: string, data: AdPlacement) => {
    if (key && data) {
      this.adPlacements.set(key, data);
    }
    return this.cache;
  };

  @action
  getAdPlacement = (key: string) => {
    return this.adPlacements.get(key);
  };

  @action
  removeAdPlacement = (key: string) => {
    return this.hasAdPlacement(key) && this.adPlacements.delete(key);
  };

  trackPageViews = () => {
    this.adobe?.trackPageView();
    this.parsely?.trackPageView();
    this.comscore?.trackPageView();
  };

  hasAdPlacement = (key: string) => {
    return this.adPlacements && this.adPlacements.has(key);
  };

  getSdkOpts = () => (this.sdkOpts = sdkOpts());

  getDefaultCategoryCatalog() {
    return this.defaultCategoryCatalog;
  }

  setDefaultCategoryCatalog(data: DefaultCategoriesCatalogResponse) {
    this.defaultCategoryCatalog = data;
  }
}

export const RESPONSIVE_IMAGE = {
  responsiveImageInitialWidth: 288,
  responsiveImageInitialHeight: 162,
  thumbImageWidth: 100,
  thumbImageHeight: 100,
  userProfileHeight: 24,
  userProfileWidth: 24,
  onAirScheduleThumbHeight: 70,
  onAirScheduleThumbWidth: 70,
  showThumbWidth: 160,
  showThumbHeight: 160,
  chartListItemThumb: 72,
  musicTrackImageWidth: 56,
  stationLogoMobileWidth: 180,
  stationLogoMobileHeight: 60,
  stationLogoDesktopWidth: 300,
  stationLogoDesktopHeight: 100,
  trafficTileImageWidth: 239,
  trafficTileImageHeight: 169,
  premiereLogoDesktopWidth: 324,
  premiereLogoDesktopHeight: 131,
  premiereLogoMobileWidth: 154,
  premiereLogoMobileHeight: 80,
  premiereLogoWithHostDesktopWidth: 266,
  premiereLogoWithHostDesktopHeight: 83,
  premiereLogoWithHostMobileWidth: 166,
  premiereLogoWithHostMobileHeight: 80,
  imageAssetInitialWidth: 740,
  imageAssetInitialHeight: 416,
  authorThumbHeight: 128,
  authorThumbWidth: 128,
};

export const COMPONENT_BREAKPOINTS = {
  carouselSlidesBreakpoint: 620,
  mediumScreenBreakpoint: 640,
  headerGridBreakpoint: 400,
  smallTabletBreakpoint: 768,
  mobileBreakpoint: 1060,
};

export const ADVERTISING_PHONE_NUMBER = "1-844-AD-HELP-5";

import { ILog } from "@ihr-radioedit/inferno-core";
import { Deferred } from "@ihr-radioedit/inferno-core";

import { promiseTimeout } from "../../utilities/promise-timeout";
import { BiddingProvider, BiddingProviderArgs, BiddingProviderSlot } from "./index";

const log = ILog.logger("AmazonBidding");

declare global {
  interface Window {
    apstag: {
      init: (opts: { pubID: string; adServer: string }) => void;
      fetchBids: (opts: { slots: AmazonBiddingSlot[] }, cb?: () => void) => void;
      setDisplayBids: () => void;
    };
  }
}

interface AmazonBiddingSlot {
  slotID: string;
  slotName: string;
  sizes?: number[][] | string;
}

export class AmazonBidding implements BiddingProvider {
  private _library?: Promise<void>;
  private libId = "amazon-tam-lib";
  private pendingFetchPromise: Deferred<boolean> | null = null;

  constructor(private opts: BiddingProviderArgs) {}

  loadLibrary() {
    if (this._library) {
      return this._library;
    }

    const { env } = this.opts.store;
    this._library = new Promise<void>((resolve, reject) => {
      const head = document.querySelector("head");
      const script = document.createElement("script");
      if (head && env.AMAZON_TAM_LIBRARY) {
        script.id = this.libId;
        script.async = true;
        script.defer = true;
        script.src = env.AMAZON_TAM_LIBRARY;
        script.onload = () => {
          log.debug("Amazon TAM library loaded");
          window.apstag.init({
            pubID: env.AMAZON_PUBLISHER_ID || "",
            adServer: "googletag",
          });
          resolve();
        };

        script.onerror = (
          event: Event | string,
          source?: string,
          fileno?: number,
          columnNumber?: number,
          error?: Error,
        ) => {
          if (error) {
            log.debug("Amazon TAM library could not be loaded", error.message);
          }
          reject(new Error("Amazon TAM library could not be loaded"));
        };

        head.appendChild(script);
      } else {
        log.debug(`Amazon TAM library could not be loaded: head? ${!!head} configured? ${env.AMAZON_TAM_LIBRARY}`);
        reject(new Error("Amazon TAM library could not be loaded"));
      }
    });

    return this._library;
  }

  async fetch(slots?: BiddingProviderSlot[]) {
    const amazonSlots: AmazonBiddingSlot[] =
      slots?.map(item => {
        return {
          slotID: item.slot.getSlotElementId(),
          slotName: item.slot.getAdUnitPath(),
          sizes: item.sizes,
        };
      }) || [];

    return promiseTimeout(
      this.opts.timeout,
      this.loadLibrary()
        .then(async () => {
          if (!amazonSlots?.length) {
            return Promise.reject(false);
          }

          if (!this.pendingFetchPromise) {
            this.pendingFetchPromise = new Deferred<boolean>();
          }

          window.apstag.fetchBids({ slots: amazonSlots }, () => {
            window.apstag.setDisplayBids();
            this.pendingFetchPromise?.resolve(true);
          });

          return this.pendingFetchPromise.promise;
        })
        .catch((e: Error) => {
          log.debug(e.message);
          return Promise.reject(false);
        }),
    );
  }
}

import { SignalDispatcher, SimpleEventDispatcher } from "strongly-typed-events";

import { Deferred, ILog, startup, StartupEvent } from "@ihr-radioedit/inferno-core";
import { BlockFragment, PageFragment } from "@ihr-radioedit/inferno-webapi";
import { BaseStore, CcpaStatus } from "../abstracts/store/base-store.abstract";
import type { Store } from "../stores";
import { cookies } from "../utilities/cookie";
import { isWindowDefined } from "../utilities/window";
import { BIDDING_PROVIDERS, BIDDING_TIMEOUT, BiddingManager } from "./bidding";
import { resizeWatcher } from "./resize-watcher";

export interface AdPlacement {
  id: string;
  element: HTMLElement;
  initialized: boolean;
  position: string;
  collapse: boolean;
  lazy: boolean;
  visibleChangeRefresh: boolean;
  split: string;
}

export abstract class AdsBase {
  static PUSHDOWN_INDEX = "7004";
  static POSITION_ATTRIBUTE = "data-position";
  static ADSPLIT_ATTRIBUTE = "data-split";
  static SELECTORS = {
    adSlots: "component-ad-unit:not(.initialized)",
    collapse: "ad-collapse",
    lazyLoad: "lazy-load",
    initialized: "initialized",
    refresh: "viewport-refresh",
  };

  protected intersection: IntersectionObserver | null = null;

  protected abstract store: BaseStore;
  protected abstract gpt: GPTUtilsBase;
  abstract init(): Promise<boolean>;
  abstract clear(): void;
  abstract handleMutationEvent(): void;
  abstract slotRendered(event: googletag.events.SlotOnloadEvent): void;
  abstract handleIntersectionUpdate(entries: IntersectionObserverEntry[]): void;
  protected abstract scanForSlots(node: HTMLElement | Document): void;
  abstract scan(): boolean;
  abstract cleanup(): void;
}

export interface SlotState {
  id: string;
  visible: boolean;
  displayed: boolean;
  rendered: boolean;
  slot: googletag.Slot;
  element: HTMLElement;
  visibleChangeRefresh: boolean;
}

export interface InfernoSlot extends SlotState {
  position_id: string;
  sizes?: number[][] | string;
}

export abstract class GPTUtilsBase {
  protected active = true;
  protected configuredSlots: { [k: string]: InfernoSlot } = {};
  protected pendingPromise: Deferred<boolean> | null = null;

  protected abstract pub?: googletag.PubAdsService;
  protected _onSlotRendered = new SimpleEventDispatcher<googletag.events.SlotRenderEndedEvent>();
  protected refreshInProgress = false;
  get onSlotRendered() {
    return this._onSlotRendered.asEvent();
  }

  abstract queue(fn: () => void): void;
  abstract init(): Promise<boolean>;
  abstract configurePubAdsService(): void;
  abstract enableServices(): Promise<void>;
  abstract setBaseTargeting(): void;
  abstract clearDisplayed(): void;
  abstract destroySlots(): void;
  abstract isRendered(position: string): boolean;
  abstract isSlotRenderEndedEvent(event: any): event is googletag.events.SlotRenderEndedEvent;
  abstract handleSlotRenderEnded(
    event:
      | googletag.events.ImpressionViewableEvent
      | googletag.events.SlotOnloadEvent
      | googletag.events.SlotRenderEndedEvent,
  ): void;
  abstract refreshAllVisibleAds(): void;
  abstract setupSlotTargeting(slot: googletag.Slot, position_id: string): void;
  abstract createSlot(
    id: string,
    position_id: string,
    element: HTMLElement,
    collapsible: boolean,
    visibleChangeRefresh: boolean,
    split: string,
  ): void;
  abstract resolveSlot(position_id: string): void;
  abstract setVisible(id: string, visible: boolean): void;
}

const log = ILog.logger("ADS");

export const getPushDown = (currentPage?: PageFragment | null) => {
  const pushdown = currentPage?.blocks.find(block => block.region === "ad:top-leaderboard");
  if (!pushdown || pushdown?.value?.position === "0") {
    log.error("No pushdown found");
    return null;
  }
  return pushdown;
};

export const isStickyPushdown = (pushdown?: BlockFragment | null) =>
  pushdown?.tags?.includes("display-hints/sticky-ad") || false;

// eslint-disable-next-line @typescript-eslint/ban-types
function getUnique<T extends {}>(arr: T[]) {
  const s = new Set<string>();
  return arr.filter(a => {
    const keep = !s.has(a.toString());
    s.add(a.toString());
    return keep;
  });
}

declare global {
  // This is the public interface for pushdown ads to call back. DO NOT CHANGE!
  interface Window {
    $Ads: {
      pdInstance: PushdownAd | null;
      pushdownExpand: (cb?: () => void) => void;
      pushdownCollapse: (cb?: () => void) => void;
      initRails: (data: { [key: string]: string }, url: string) => void;
    };
    googletag: googletag.Googletag;
    adSlotsRendered: string[];
    gptAdSlots: { [k: string]: googletag.Slot };
    collapse: () => void;
    expand: () => void;
  }
}

export class Ads extends AdsBase {
  constructor(protected store: Store, protected gpt: GPTUtils) {
    super();
  }

  init = async () => {
    const { site } = this.store;
    if (typeof IntersectionObserver === "undefined") {
      log.debug("No IntersectionObserver");
      return false;
    }

    if (!site.sections.ads?.dfp_switch || !this.gpt.active) {
      log.debug("DFP Ads disabled");
      return false;
    }

    return this.gpt
      .init()
      .then(() => {
        const observer = new MutationObserver(() => this.handleMutationEvent());
        observer.observe(document.body, { childList: true, subtree: true });

        resizeWatcher.onWidthChange.subscribe(() => {
          this.gpt.clearDisplayed();
          this.gpt.refreshAllVisibleAds();
        });

        this.gpt.onSlotRendered.subscribe((e: googletag.events.SlotRenderEndedEvent) => this.slotRendered(e));
        this.store.onIntersectAction.subscribe(e => {
          const { entry } = e;
          if (entry.isIntersecting) {
            this.gpt.setVisible(entry.target.id, entry.isIntersecting);

            if (!resizeWatcher.resizeInProgress) {
              this.gpt.refreshAllVisibleAds();
            }
          }
        });

        return true;
      })
      .catch(error => {
        log.warn("Error initializing Ads: ", error);
        return false;
      });
  };

  clear = () => {
    this.gpt.clearDisplayed();
  };

  handleMutationEvent = () => this.scan();

  slotRendered = (event: googletag.events.SlotRenderEndedEvent) => {
    const { isEmpty, slot } = event;
    const [position] = slot.getTargeting("pos");
    const id = `dfp-ad-${position}`;

    const placement = document.getElementById(id);

    if (position === Ads.PUSHDOWN_INDEX && !isEmpty) {
      const slotState = this.gpt.configuredSlots[id];
      if (slotState) {
        window.$Ads.pdInstance = new PushdownAd(slotState);
      }
    }

    if (position === "3317" && isEmpty) {
      if (placement) {
        placement.remove();
      }
    }

    if (placement && !isEmpty) {
      placement.classList.add("slot-rendered");
    }
  };

  /**
   * Deprecated
   * */
  handleIntersectionUpdate = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(e => {
      this.gpt.setVisible(e.target.id, e.isIntersecting);
    });

    if (!resizeWatcher.resizeInProgress) {
      this.gpt.refreshAllVisibleAds();
    }
  };

  /**
   * Scan the page for ad slots and process all of the directives
   * present on the HTML element.
   */
  protected scanForSlots(node: HTMLElement | Document) {
    const adElements = node.querySelectorAll(`.${Ads.SELECTORS.adSlots}`);
    return Array.from(adElements).map(e => {
      const collapse = e.classList.contains(Ads.SELECTORS.collapse);
      const lazy = e.classList.contains(Ads.SELECTORS.lazyLoad);
      const initialized = e.classList.contains(Ads.SELECTORS.initialized);
      const visibleChangeRefresh = e.classList.contains(Ads.SELECTORS.refresh);
      const position = e.getAttribute(Ads.POSITION_ATTRIBUTE);
      const split = e.getAttribute(Ads.ADSPLIT_ATTRIBUTE);
      return {
        id: e.id,
        element: e,
        initialized,
        position,
        collapse,
        lazy,
        visibleChangeRefresh,
        split,
      } as AdPlacement;
    });
  }

  /**
   * Scan the DOM looking for ad placements that represent a this.gpt
   * ad slot. Then push commands onto the command queue that will
   * render the ads.
   */
  scan = () => {
    if (!this.gpt.active) {
      return false;
    }

    this.scanForSlots(document).forEach(({ id, position, collapse, element, lazy, visibleChangeRefresh, split }) => {
      if (element) {
        element.classList.add(Ads.SELECTORS.initialized);
        this.gpt.createSlot(id, position, element, collapse, visibleChangeRefresh, split);
        if (!lazy) {
          this.gpt.setVisible(id, true);
        }
      }
    });

    this.gpt.refreshAllVisibleAds();

    return true;
  };

  public cleanup = () => {
    this.gpt.destroySlots();
  };
}

const logPD = ILog.logger("Ads");
class PushdownAd {
  static INIT_CLASS = "pd-init";
  static PUSHDOWN_TIMEOUT = 10000;
  private readonly gate_cookie: string = "";
  private readonly adIframe: HTMLIFrameElement | null = null;

  private _onTransitionEnd = new SignalDispatcher();
  get onTransitionEnd() {
    return this._onTransitionEnd.asEvent();
  }

  constructor(private slot: SlotState) {
    const alreadyInitialized = slot.element.classList.contains(PushdownAd.INIT_CLASS);
    if (alreadyInitialized) {
      logPD.warn(`PD=${slot.id} has already been initialized`);
    } else {
      slot.element.classList.add(PushdownAd.INIT_CLASS);
      this.gate_cookie = `pd-seen-${slot.id}`;
      this.adIframe = slot.element.querySelector("iframe")!;
      if (this.adIframe) {
        this.adIframe.addEventListener("transitionend", () => {
          // this.handleTransitionEnd();
          this._onTransitionEnd.dispatch();
        });

        const seenWithin24Hours = cookies().get(this.gate_cookie);

        if (!seenWithin24Hours) {
          this.pushdownAutoStart();
        }
      }
    }
  }

  pushdownAutoStart() {
    const iframe = this.adIframe;

    if (iframe && iframe.contentWindow && typeof iframe.contentWindow.expand === "function") {
      iframe.contentWindow.expand(); // Remote code within the iframe.
      this.pushdownExpand();

      window.setTimeout(() => {
        if (iframe && iframe.contentWindow && typeof iframe.contentWindow.collapse === "function") {
          iframe.contentWindow.collapse();
        }
        cookies().set(this.gate_cookie, "1", 1);
      }, PushdownAd.PUSHDOWN_TIMEOUT);
    } else {
      this.slot.element.remove();
    }
  }

  // bound to the public interface. Don't change without due care.
  // TODO differentiate between expanding and collapsing
  pushdownExpand(callback?: () => void) {
    if (typeof callback === "function") {
      this.onTransitionEnd.one(callback);
    }
    if (this.adIframe) {
      this.adIframe.classList.add("expanded");
    }
  }

  // bound to the public interface. Don't change willynilly.
  // TODO differentiate between expanding and collapsing
  pushdownCollapse(callback?: () => void) {
    if (typeof callback === "function") {
      this.onTransitionEnd.one(callback);
    }
    if (this.adIframe) {
      this.adIframe.classList.remove("expanded");
    }
  }
}

export const GPT_LIBRARY_URL = "//www.googletagservices.com/tag/js/gpt.js";
export const GPT_LIBRARY_ID = "gpt-lib";

const logGPT = ILog.logger("GPTUtils/ads.ts");
export class GPTUtils extends GPTUtilsBase {
  active = true;
  configuredSlots: { [k: string]: InfernoSlot } = {};
  pendingPromise: Deferred<boolean> | null = null;

  protected pub?: googletag.PubAdsService;

  constructor(protected store: Store, protected bidding?: BiddingManager) {
    super();
    // stubs for googletag
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
  }

  queue(fn: () => void) {
    if (this.active && window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(fn);
    }
  }

  async init(): Promise<boolean> {
    logGPT.debug("Initializing GPT Utils");
    if (!this.pendingPromise) {
      this.pendingPromise = new Deferred<boolean>();
    }

    BIDDING_PROVIDERS.map(provider =>
      this.bidding?.queue(
        new provider({
          store: this.store,
          timeout: BIDDING_TIMEOUT,
          googletag: window.googletag,
        }),
      ),
    );

    if (isWindowDefined()) {
      const head = document.querySelector("head");
      let script = document.getElementById(GPT_LIBRARY_ID) as HTMLScriptElement;
      if (head && !script) {
        script = document.createElement("script");

        script.id = GPT_LIBRARY_ID;
        script.async = true;
        script.defer = true;
        script.src = GPT_LIBRARY_URL;
        script.onload = () => {
          logGPT.debug("GPT Library Loaded");
          this.configurePubAdsService();
        };

        script.onerror = () => {
          logGPT.debug("Error loading GPT Library");
          this.pendingPromise?.reject(false);
        };
        head.appendChild(script);
      }
    } else {
      this.pendingPromise?.reject(false);
    }

    return this.pendingPromise.promise;
  }

  configurePubAdsService = () => {
    this.queue(async () => {
      const { session } = this.store;
      logGPT.debug("Configuring Google PubAds Service");
      this.pub = window.googletag.pubads();
      this.pub.disableInitialLoad();
      this.pub.enableAsyncRendering();
      this.pub.enableSingleRequest();
      this.setBaseTargeting();

      // Wait for MOAT
      try {
        await startup.init().waitFor(StartupEvent.MOAT);
      } catch (err) {
        logGPT.warn("Startup Event for MOAT never fired", err);
      }

      this.pub.addEventListener("slotRenderEnded", e => this.handleSlotRenderEnded(e));

      let restrictDataProcessing =
        (isWindowDefined() && window.location.search.toLowerCase().includes("ccpa")) || false;
      if (session.currentSession) {
        if (session.currentSession.state.authenticated && session.currentSession.hasPrivacy) {
          restrictDataProcessing = this.store?.getCcpaStatus() === CcpaStatus.OptOut;
        }

        session.currentSession.onStatusChanged.sub(() => {
          this.pub?.setPrivacySettings?.({
            restrictDataProcessing: this.store?.getCcpaStatus() === CcpaStatus.OptOut,
          });
        });
      } else {
        logGPT.debug("No current session");
      }

      logGPT.debug("restrictDataProcessing: ", restrictDataProcessing);
      this.pub?.setPrivacySettings?.({
        restrictDataProcessing,
      });
      window.googletag.enableServices();
      this.pendingPromise?.resolve(true);
    });
  };

  enableServices = () => {
    return new Promise<void>(res => {
      this.queue(() => {
        window.googletag.enableServices();
        res();
      });
    });
  };

  setBaseTargeting = () => {
    this.pub?.setTargeting("hn", window.location.hostname);
    const tags = this.store?.tags.ads;
    if (tags?.env) {
      this.pub?.setTargeting("env", tags.env);
    }
    this.pub?.setTargeting("referrer", document.referrer);
    this.pub?.setTargeting("vers", "Inferno");
  };

  clearDisplayed() {
    this.queue(() => {
      this.pub?.clearTargeting();
      this.setBaseTargeting();
      Object.values(this.configuredSlots).forEach(slot => {
        slot.displayed = false;
        slot.rendered = false;
        slot.slot.clearTargeting();
        this.setupSlotTargeting(slot.slot, slot.position_id);
      });
    });
  }

  setVisible(id: string, visible: boolean) {
    this.queue(() => {
      const slot = this.configuredSlots[id];
      if (slot && slot.visibleChangeRefresh && slot.visible && !visible) {
        slot.displayed = false;
      }
      if (slot) {
        slot.visible = visible;
      }
    });
  }

  refreshAllVisibleAds() {
    this.queue(() => {
      const slots = Object.values(this.configuredSlots).filter(s => s.visible && !s.displayed);
      if (slots.length && !this.refreshInProgress) {
        this.refreshInProgress = true;
        this.bidding
          ?.refresh(
            slots.map(s => {
              return { slot: s.slot, sizes: s.sizes || [] };
            }),
          )
          .catch(error => {
            logGPT.debug(error);
          })
          .finally(() => {
            window.googletag.pubads().refresh(slots.map(s => s.slot));
            slots.forEach(s => (s.displayed = true));
            this.refreshInProgress = false;
          });
      }
    });
  }

  resolveSlot(position_id: string) {
    const position = this.store?.site.config.adPositions.find(p => p.positionId === position_id);
    if (!this.store || !position) {
      return;
    }

    const market = this.store.site.index.market.replace("markets/", "");
    const { facets } = this.store.site.index;
    return {
      dfp_id: `dfp-ad-${position_id}`,
      market,
      ad_site: market.replace("-", ".").toLowerCase(),
      station: this.store.site.index.slug.toLowerCase(),
      format: facets.map(facet => facet.replace("formats/", "")),
      ...position,
    };
  }

  createSlot(
    id: string,
    position_id: string,
    element: HTMLElement,
    collapsible = true,
    visibleChangeRefresh = false,
    split: string,
  ) {
    this.queue(async () => {
      let allSizes: number[][] = [];
      const mapping = googletag.sizeMapping();
      const adpos = this.resolveSlot(position_id);

      if (this.configuredSlots[id]) {
        logGPT.debug("slot: ", this.configuredSlots[id]);
        return;
      }
      if (!adpos) {
        logGPT.debug(`Couldn't find configured position for ${position_id}`);
        return;
      }
      adpos.breakpoints.forEach(browser_size => {
        if (browser_size.sizes) {
          const sizes = browser_size.sizes.filter(s => s.w && s.h).map(size => [size.w, size.h]);
          allSizes = allSizes.concat(sizes);
          mapping.addSize([browser_size.breakpoint, 0], sizes);
        }
      });
      // IHRAL-6481 : use adSplit from adBlock
      // Append a ".n" to the name based on a random split
      const adSplit = Math.floor(Math.random() * 100) <= parseInt(split, 10) ? ".n" : "";
      const name = `/6663/ccr.${adpos.ad_site.toLowerCase()}${adSplit}/${adpos.station.toLowerCase()}`;
      // -1,-1 is used to trigger 'fluid' size ads
      // so if any entry is [-1,-1] use a "fluid" add
      const isFluid = !!allSizes.map(s => s[0] === -1 && s[1] === -1).filter(Boolean).length;
      const slotSizes = isFluid ? "fluid" : getUnique(allSizes);
      const slot = window.googletag.defineSlot(name, slotSizes, id);

      this.setupSlotTargeting(slot, position_id);

      slot.defineSizeMapping(mapping.build()).setCollapseEmptyDiv(true, true).addService(window.googletag.pubads());

      this.configuredSlots[id] = {
        id,
        slot,
        position_id,
        element,
        visibleChangeRefresh,
        displayed: false,
        visible: false,
        rendered: false,
        sizes: slotSizes,
      };

      slot.setCollapseEmptyDiv(collapsible);
      return slot;
    });
  }

  setupSlotTargeting(slot: googletag.Slot, position_id: string) {
    const adpos = this.resolveSlot(position_id);
    if (!adpos) {
      return;
    }
    /*
            TODO - waiting on AR-5376
            if (site.sections.ads.headerBidding) {
                slot.setTargeting("ix-adslot", id);
            }
        */
    slot.setTargeting("ccrpos", [adpos.positionId]);
    slot.setTargeting("pos", [adpos.positionId]);
    slot.setTargeting("market", [adpos.market]);

    const tags = this.store?.tags.ads;
    if (tags) {
      slot.setTargeting("format", tags.format);
      slot.setTargeting("genre", tags.genre);
      slot.setTargeting("keywords", tags.keywords);
      slot.setTargeting("topics", tags.topics);
      slot.setTargeting("path", tags.path);
      slot.setTargeting("contenttype", tags.type);
      if (tags.microsite !== "") {
        slot.setTargeting("microsite", tags.microsite);
      }
    }
  }

  destroySlots() {
    this.queue(() => {
      window.googletag.destroySlots();
    });
  }

  isRendered(position: string) {
    const state = this.configuredSlots[position];
    if (state) {
      return state.rendered;
    }
    return false;
  }

  isSlotRenderEndedEvent(event: any): event is googletag.events.SlotRenderEndedEvent {
    return event;
  }

  handleSlotRenderEnded(
    event:
      | googletag.events.ImpressionViewableEvent
      | googletag.events.SlotOnloadEvent
      | googletag.events.SlotRenderEndedEvent,
  ) {
    const [position] = event.slot.getTargeting("pos");
    const state = this.configuredSlots[position];

    if (state) {
      state.rendered = true;
    }

    if (this.isSlotRenderEndedEvent(event)) {
      this._onSlotRendered.dispatch(event);
    }
  }
}

export const adsHelper = (store: Store) => {
  if (isWindowDefined()) {
    return new Ads(store, new GPTUtils(store, new BiddingManager(store)));
  }
};

// Block.value.position: 0 means No Ads
export const blockHaveAd = (adInsertPosition: number) => adInsertPosition > 0;

export const showLoadMoreAd = (itemCount: number, adInsertPosition: number, idx: number, loadMore: boolean) => {
  const hasMoreThanOneBatch = itemCount > adInsertPosition;
  const notLastBatch = idx + 1 < itemCount;
  const atEndOfBatch = (idx + 1) % adInsertPosition === 0;
  return loadMore && hasMoreThanOneBatch && notLastBatch && atEndOfBatch;
};

import { createBrowserHistory } from "history";
import i18next from "i18next";

import i18n from "@inferno/renderer-shared-i18n";
import { environ } from "@ihr-radioedit/sdk-utils";
import { ILog, getLocale } from "@ihr-radioedit/inferno-core";
import {
  Store,
  isWindowDefined,
  IHRSessionState,
  SessionBackend,
  getDeviceId,
  getSessionBackend,
  generateDeviceId,
  getUserTestGroups,
  storeDeviceId,
  storeTestGroups,
  AdobeManager,
  adsHelper,
} from "@inferno/renderer-shared-core";

import { performance } from "./helpers";
import { setBeacon } from "../app/core/lib/beacon";
import { ComScoreManager } from "../app/core/lib/comScore";
import { initFbPixel, initFbSDK } from "../app/core/lib/integrations/facebook";
import { initIab, uspApi } from "../app/core/lib/iab";
import { setupIglooHandler } from "../app/core/lib/igloo";
import { setupLiveRamp } from "../app/core/lib/integrations/live-ramp";
import { ParselyManager } from "../app/core/lib/integrations/parsely";
import { setupSocialMedia } from "./socialMediaLibs";
import { setupExco } from "../app/core/lib/integrations/exco";
import { setupOutbrain } from "../app/core/lib/integrations/outbrain";

const log = ILog.logger("client/setup.tsx");

export function bootstrap() {
  log.debug("1.) Bootstrap Start");
  // Grab the state from a global variable injected into the server-generated HTML
  const preloadedState = window.__PRELOADED_STATE__;

  // Allow the passed state to be garbage-collected
  delete window.__PRELOADED_STATE__;
  log.debug("1.) Preloaded State: ", preloadedState);

  // Set Log Level
  if (preloadedState.env && (preloadedState.env.LOG_LEVEL || preloadedState.env.DEBUG)) {
    const logLevel = preloadedState.env.DEBUG === "1" ? "debug" : preloadedState.env.LOG_LEVEL?.toLowerCase() || "info";
    log.setLevel(logLevel);
  }

  // Override API_BASE_URI
  preloadedState.api_base_uri = preloadedState.env.API_BASE_URI ? preloadedState.env.API_BASE_URI : "/api/v4";

  // Set up application
  const browserHistory = createBrowserHistory();
  const store = new Store(preloadedState);
  const no3rdParty = store.disableThirdParty;

  log.debug("1.) Bootstrap Finished");
  return { store, browserHistory, no3rdParty };
}

export function initSession(store: Store, no3rdParty: boolean) {
  log.debug("2.) InitSession Start");
  // Determine Session Type / Load session from Local Storage if it exits
  store.session.storeSession(
    getSessionBackend(
      store,
      environ.get("AB_TEST_HOST", ""),
      parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
    ) as SessionBackend,
  );

  if (!no3rdParty) {
    log.debug("2.) Refreshing Session Data");
    store.session.currentSession?.refresh().catch(err => log.error("2.) Error Refreshing Session", err));
  }
  log.debug("2.) InitSession End");
}

export async function initABTestGroups(store: Store, no3rdParty: boolean) {
  log.debug("3.) Init AB Test Groups Start");
  const deviceId = getDeviceId() || generateDeviceId();
  if (!no3rdParty) {
    log.debug("3.) Getting AB Test Groups (Second Await)");
    const testGroups = await getUserTestGroups(
      deviceId,
      store.site.index.slug,
      (store.session.currentSession?.state as IHRSessionState).profile ?? null,
      environ.get("AB_TEST_HOST", ""),
      parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
      "index.tsx",
    );
    store.testGroups = testGroups;
    storeTestGroups(testGroups);
  }
  storeDeviceId(deviceId);
  log.debug("3.) Init AB Test Groups Finished");
}

export function initLazySizes() {
  try {
    window.lazySizes.init();
    log.debug("7.) Lazysizes initialized");
  } catch (ignore) {
    // pass
    log.debug("7.) Lazysizes NOT initialized");
  }
}

export async function initLocale(store: Store) {
  log.debug("4.) InitLocale (Third Await)");
  await i18n(getLocale(store.site).split("-")[0]);
  log.debug("4.) InitLocale Finished");
}

// Ensure scripts here are set with async or defer only to not block hydration
export function loadIntegrationScripts(_store: Store) {
  log.debug("5.) Preload Integrations Start");
  log.debug("5.) Preload Integrations Finished");
}

// Setups third party integrations (ads, adobe, analytics, etc.)
export function initIntegrations(store: Store, no3rdParty: boolean) {
  log.debug("9.) Integration Callback Start");

  performance("mark", ["Inferno - Integration Callback"]);

  if (no3rdParty) {
    return performance("mark", ["Inferno - App Loaded - No 3rd Party"]);
  }

  if (isWindowDefined()) {
    // stubs for googletag
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    const ads = adsHelper(store);
    ads
      ?.init()
      .then(() => {
        log.debug("Ads Helper Ready");
        store.ads = ads;
        ads.scan();
      })
      .catch(err => log.error(`${i18next.t("ads_init")}: ${err}`));
  }

  setBeacon(store);
  new AdobeManager(store);
  new ParselyManager(store);
  new ComScoreManager(store);
  setupLiveRamp(store);
  setupIglooHandler(store);
  setupOutbrain(store);
  setupExco(store);

  try {
    window.__uspapi = uspApi;
  } catch (ignore) {
    log.debug("uspapi not available");
  }
  setupSocialMedia();
  performance("mark", ["Inferno - App Loaded"]);

  store.session.currentSession
    ?.refresh()
    .catch(e => log.error("Session Refresh Error", e))
    .finally(() => {
      // third parties lib for loggedIn user
      initIab(store);
      initFbSDK(store);
      initFbPixel(store);
    });
  store.session.currentSession?.onStatusChanged.sub(() => {
    initIab(store, true);
  });
  log.debug("9.) Integration Callback Finished");
}

import { ILog } from "@ihr-radioedit/inferno-core";
import { SessionType, cookies, type Store, OneTrustManage, CcpaStatus } from "@inferno/renderer-shared-core";

const log = ILog.logger("onetrust.ts");

declare global {
  interface Window {
    OptanonWrapper: () => void;
    Optanon: {
      ToggleInfoDisplay: () => void;
    };
    OnetrustActiveGroups: string;
    OneTrust: {
      UpdateConsent: (category: string, value: string) => Promise<void>;
      dataSubjectParams: {
        id: string;
        isAnonymous: boolean;
        token: string;
      };
    };
  }
}

export class OneTrustManager extends OneTrustManage {
  protected _library?: Promise<void>;
  private _consentNoticeLibrary?: Promise<void>;
  private disabled = false;
  private mounted = false;

  constructor(protected store: Store) {
    super();
    store.oneTrust = this;
  }
  static cookieTokenName = "OTTok";
  static cookieIdName = "OTPid";
  static cookieProfileIdName = "pid";
  static cookieUserTypeName = "userType";
  static coastConsentId = "4f76ca8a-4263-4392-8880-c448aa78ab63";
  static localConsentId = "7d044e9d-e966-4b73-b448-a29d06f71027";
  static category = "Category";
  static categoryValue = "C0004";

  private notAnonymous = (accountType: string | undefined) =>
    accountType ? accountType.toLowerCase() !== "anonymous" : false;

  private getCookieDomain() {
    const hostname = window.location.hostname;
    if (hostname.endsWith("ihrint.com")) {
      return ".ihrint.com";
    } else if (hostname.endsWith("iheart.com")) {
      return ".iheart.com";
    } else {
      return "";
    }
  }

  private setCookies = (token: string, identifier: string) => {
    const domain = this.getCookieDomain();
    const cookieAttributes = `domain=${domain}; max-age=28800; SameSite=None; Secure; Path=/`;

    cookies().setRaw(OneTrustManager.cookieTokenName, token, cookieAttributes);
    cookies().setRaw(OneTrustManager.cookieIdName, identifier, cookieAttributes);
  };

  private setAttributes = (element: Element, attributes: { [key: string]: string }) => {
    for (const key in attributes) {
      if (attributes.hasOwnProperty(key)) {
        element.setAttribute(key, attributes[key]);
      }
    }
  };

  private getConsentScriptUrl = () => {
    const id =
      this.store.site.config.sections?.partners?.auth_type?.taxo?.name === SessionType.PWS_AUTH
        ? OneTrustManager.coastConsentId
        : OneTrustManager.localConsentId;
    return {
      id,
      url: `${this.store.env.ONETRUST_CONSENT_PATH}/${id}/otSDKStub.js`,
    };
  };

  private makeOptOutUpdateConsent = () => ({
    category: OneTrustManager.category,
    value: `${OneTrustManager.categoryValue}:0`,
  });

  private updateOptOutConsent = () => {
    const { category, value } = this.makeOptOutUpdateConsent();
    window.OneTrust?.UpdateConsent(category, value);
  };

  private async getToken(identifier: string): Promise<string | undefined> {
    const resp = await fetch(this.store.env.ONETRUST_TOKEN_URL + identifier);
    if (resp?.ok) {
      return resp.json();
    }
  }

  disable = () => {
    this.disabled = true;
  };

  setOptOutConsent = (from: "ClientNavigation" | "Load") => {
    if (!this.disabled && this.store.isIHRSession === CcpaStatus.OptOut && !this.isOptOut()) {
      log.debug(`Updating OneTrust Consent OptOut On ${from}`);
      this.updateOptOutConsent();
    }
  };

  isOptOut = () => {
    return (
      !this.disabled &&
      !!window?.OnetrustActiveGroups &&
      !window.OnetrustActiveGroups.includes(OneTrustManager.categoryValue)
    );
  };

  setBootstrap() {
    return new Promise<void>(resolve => {
      window.OptanonWrapper = () => {
        // mounted is needed since OptanonWrapper not only gets called on load but every time you window.OneTrust?.UpdateConsent() :\
        if (!this.mounted) {
          this.setOptOutConsent("Load");
          resolve();
          this.mounted = true;
        }
      };
    });
  }

  toggleInfoDisplay = async (e: Event) => {
    e.preventDefault();

    if (!this.disabled && window.Optanon?.ToggleInfoDisplay) {
      window.Optanon.ToggleInfoDisplay();
    } else {
      log.error("Cant toggle, check scripts were loaded correctly");
    }
  };

  async init() {
    const token = cookies().get(OneTrustManager.cookieTokenName);
    const id = cookies().get(OneTrustManager.cookieIdName);

    if (token || id) {
      log.debug("OneTrustManager: Existing token and id found.. setting dataSubjectParams"); // this doesnt make sense.. cause what happens if the user logs out.. refreshes the page..this cookie doesnt get destroyed so it'll be out of sync
      window.OneTrust = {
        ...(window.OneTrust ?? {}),
        dataSubjectParams: {
          id: cookies().get(OneTrustManager.cookieIdName),
          isAnonymous: false,
          token: cookies().get(OneTrustManager.cookieTokenName),
        },
      };
    } else {
      if (window.IHR?.initialized && window.IHR?.api?.profile?.get) {
        log.debug("OneTrustManager: No Existing Token found.. IHR SDK initialized and ready.. making profile call");
        const resp = await window.IHR.api.profile.get();
        const accountType = resp?.accountType;
        const profileId = resp?.profileId;
        if (this.notAnonymous(accountType) && profileId) {
          const identifier = profileId.toString();
          const tokenResponse = await this.getToken(identifier);
          if (tokenResponse) {
            this.setCookies(tokenResponse, identifier);
          }
        }
      } else {
        log.debug(
          "OneTrustManager: No Existing Token found.. IHR SDK not initialized or ready.. skipping profile call.. checking cookie set by ihr",
        );
        const profileId = cookies().get(OneTrustManager.cookieProfileIdName);
        const userType = cookies().get(OneTrustManager.cookieUserTypeName);

        if (profileId && userType && this.notAnonymous(userType)) {
          const tokenResponse = await this.getToken(profileId);
          if (tokenResponse) {
            this.setCookies(tokenResponse, profileId);
          }
        }
      }
    }
  }

  // Ensure library is loaded once and only once
  loadConsentNoticeLibrary = () => {
    if (this._consentNoticeLibrary) {
      return this._consentNoticeLibrary;
    }

    const { env } = this.store;
    this._consentNoticeLibrary = new Promise((resolve, reject) => {
      const body = document.querySelector("body");
      /* make sure this is in sync with the preload link tag set on the server */
      const { url, id } = this.getConsentScriptUrl();

      if (id && url && body) {
        const script = document.createElement("script");
        script.async = true;
        script.type = "text/javascript";
        this.setAttributes(script, {
          "data-domain-script": id,
        });
        script.src = url;
        script.onload = () => {
          log.debug(`OneTrust Consent Script loaded for ${id}`);
          resolve();
        };

        script.onerror = (
          event: Event | string,
          source?: string,
          fileno?: number,
          columnNumber?: number,
          error?: Error,
        ) => {
          if (error) {
            log.debug(`OneTrust Consent library could not be loaded for ${id}`, error.message);
          }
          reject(new Error(`OneTrust Consent library could not be loaded for ${id}`));
        };

        body.appendChild(script);
      } else {
        log.debug(
          `OneTrust Consent library could not be loaded: body? ${!!body} configured? ${env.ONETRUST_CONSENT_PATH}`,
        );
        reject(new Error("OneTrust Consent library could not be loaded"));
      }
    });

    return this._consentNoticeLibrary;
  };

  // Ensure library is loaded once and only once
  loadLibrary = () => {
    if (this._library) {
      return this._library;
    }

    const { env } = this.store;
    this._library = new Promise((resolve, reject) => {
      const body = document.querySelector("body");
      /* make sure this is in sync with the preload link tag set on the server */
      const oneTrustIabUrl = this.store.env.ONETRUST_IAB_URL;

      if (oneTrustIabUrl && body) {
        const script = document.createElement("script");

        script.type = "text/javascript";
        this.setAttributes(script, {
          "ccpa-opt-out-ids": "C0004",
          "ccpa-opt-out-geo": "all",
          "ccpa-opt-out-lspa": "true",
        });
        script.async = true;
        script.src = oneTrustIabUrl;
        script.onload = () => {
          log.debug("OneTrust IAB Script loaded");
          resolve();
        };

        script.onerror = (
          event: Event | string,
          source?: string,
          fileno?: number,
          columnNumber?: number,
          error?: Error,
        ) => {
          if (error) {
            log.debug("OneTrust IAB library could not be loaded", error.message);
          }
          reject(new Error("OneTrust IAB library could not be loaded"));
        };

        body.appendChild(script);
      } else {
        log.debug(`OneTrust IAB library could not be loaded: body? ${!!body} configured? ${env.ONETRUST_IAB_URL}`);
        reject(new Error("OneTrust IAB library could not be loaded"));
      }
    });

    return this._library;
  };

  trackPageView = () => {
    log.debug("OneTrust trackPageView not implemented.");
  };

  trigger = () => {
    log.debug("OneTrust trigger not implemented.");
  };
}
